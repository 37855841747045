<template>
  <v-container>
    <h3
      v-if="title"
      class="headline grey--text text-xs-center hidden-sm-and-down"
    >
      {{ title }}
    </h3><br>
    <v-layout row>
      <v-flex
        xs12
        md8
        offset-md2
      >
        <v-card>
          <v-card-text>
            <v-speed-dial
              v-model="dialShare"
              absolute
              right
              bottom
              direction="bottom"
              open-on-hover
            >
              <template #activator>
                <v-btn
                  fab
                  bottom
                  small
                  color="primary"
                >
                  <v-icon v-if="dialShare">
                    mdi-close
                  </v-icon>
                  <v-icon v-else>
                    mdi-share-variant
                  </v-icon>
                </v-btn>
              </template>
              <facebook
                :url="affiliateUrl"
                title="Let's join by dealing with Dealer Affiliate"
                scale="2"
              />
              <twitter
                :url="affiliateUrl"
                title="Let's join by dealing with Dealer Affiliate"
                scale="2"
              />
              <telegram
                :url="affiliateUrl"
                title="Let's join by dealing with Dealer Affiliate"
                scale="2"
              />
              <whats-app
                :url="affiliateUrl"
                title="Let's join by dealing with Dealer Affiliate"
                scale="2"
              />
            </v-speed-dial>
            <v-layout
              justify-center
              align-center
            >
              <div id="qr" />
            </v-layout>
          </v-card-text>
          <v-card-text>
            <v-layout
              justify-center
              align-center
            >
              <v-subheader>
                Scan the QR code to jump to the online store in your browser.
              </v-subheader>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import security from '@/security'
import {
  Facebook,
  Twitter,
  // Linkedin,
  Telegram,
  WhatsApp,
} from 'vue-socialmedia-share'

const qrcode = require('../qrcode.js')
const pageTitle = 'Dealer Affiliate QR Code'

export default {
  components: {
    // Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel')
    Facebook,
    Twitter,
    // Linkedin,
    Telegram,
    WhatsApp,
  },
  data () {
    return {
      title: pageTitle,
      dialShare: false,
    }
  },
  computed: {
    isAuthenticated: function () {
      return this.$store.state.security.auth.authenticated
    },
    affiliateUrl: function () {
      const baseUrl = 'https://onexox.my/onlinestore/dealer.php?dlr='
      return baseUrl + (this.isAuthenticated && security.me.dealerId() ? security.me.dealerId() : 'DYSD3613')
    },
  },
  mounted () {
    this.makeQR()
  },
  methods: {
    makeQR () {
      var qr = qrcode.QRCode(4, 'M')
      qr.addData(this.affiliateUrl)
      qr.make()
      document.getElementById('qr').innerHTML = qr.createImgTag(6)
    },
  },
}
</script>
