<template>
  <panel
    :breadcrumbs="[]"
    :title="title"
  >
    <v-alert
      :value="announcement"
      color="green"
      icon="mdi-bullhorn"
    >
      <div v-html="announcement" />
    </v-alert>
    <v-alert
      :value="month !== monthOf"
      color="info"
      icon="info"
    >
      Previous month KPI does not reflect the actual monthly KPI that has been
      finalized because subscribers may have been terminated or ported out.
    </v-alert>
    <v-toolbar
      dense
      card
    >
      <v-menu
        v-model="menu"
        lazy
        close-on-content-click
        transition="scale-transition"
        full-width
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          min="2024-02"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-flex xs3>
        <v-text-field
          v-model="search"
          label="Search"
          clearable
        />
      </v-flex>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </v-toolbar>
    <v-card
      v-if="kpi2022 && kpiAchievement"
      color="black"
    >
      <v-card-text style="height: 10px; position: relative">
        <v-btn
          absolute
          dark
          fab
          top
          right
          :ripple="false"
          large
          color="black"
        >
          <v-avatar
            size="50px"
            :color="kpiColor"
          >
            <span class="white--text subheader">{{ kpiAchievement }}</span>
          </v-avatar>
        </v-btn>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="items"
      expand
      hide-actions
      item-key="indicator"
    >
      <template #items="props">
        <tr @click="props.expanded = !props.expanded">
          <td class="text-xs-left">
            {{ props.item.indicator }}
          </td>
          <template v-if="kpiSept2022">
            <td
              v-if="props.item.indicator === 'Activation'"
              class="text-xs-center"
              v-html="props.item.bronzegoal + '<br><i>and above</i>'"
            />
            <td
              v-if="props.item.indicator === 'Activation'"
              class="text-xs-center"
              v-html="props.item.silvergoal + '<br><i>and above</i>'"
            />
            <td
              v-if="props.item.indicator === 'Activation'"
              class="text-xs-center"
              v-html="props.item.goldgoal + '<br><i>and above</i>'"
            />
          </template>
          <td class="text-xs-center primary">
            {{ props.item.achievement }}
          </td>
        </tr>
      </template>
      <template #expand="props">
        <v-card>
          <v-card-text>
            <v-data-table
              :headers="props.item.headers"
              :items="props.item.items"
              class="elevation-1"
              item-key="msisdn"
              :hide-actions="hideActions"
              :custom-filter="customFilter"
              :search="search"
            >
              <template #items="thisProps">
                <td class="text-xs-left">
                  {{ thisProps.item.name }}
                </td>
                <td class="text-xs-center">
                  {{ thisProps.item.value }}
                </td>
                <td
                  v-if="thisProps.item.active"
                  class="text-xs-center"
                >
                  <v-icon color="success">
                    mdi-check
                  </v-icon>
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  <v-icon color="error">
                    mdi-close
                  </v-icon>
                </td>
                <td
                  v-if="thisProps.item.qualified"
                  class="text-xs-center"
                >
                  <v-icon
                    v-if="
                      thisProps.item.note && thisProps.item.note === 'WAWA18'
                    "
                    color="teal"
                  >
                    mdi-fraction-one-half
                  </v-icon>
                  <v-icon
                    v-else
                    color="success"
                  >
                    mdi-check
                  </v-icon>
                </td>
                <td
                  v-else
                  class="text-xs-center"
                >
                  <v-icon color="error">
                    mdi-close
                  </v-icon>
                </td>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </template>
      <template
        v-if="!kpiSept2022"
        #footer
      >
        <td>&nbsp;</td>
        <td
          v-if="kpi2022"
          colspan="3"
          class="text-xs-right primary--text"
        >
          &nbsp;
        </td>
        <td
          v-else
          class="text-xs-center primary--text"
        >
          {{ goal }}
        </td>
        <td class="text-xs-center primary">
          {{ achievement }}
        </td>
      </template>
    </v-data-table>
    <v-alert
      class="mt-0 mb-4"
      :value="true"
      :type="alertType"
    >
      {{ alertText }}
    </v-alert>
    <v-card
      class="transparent"
      flat
    >
      <template v-if="kpi2022">
        <v-parallax
          v-if="annualSubscription.length > 0"
          dark
          height="100"
          src="/img/background.jpg"
        >
          <v-layout
            align-center
            column
            justify-center
          >
            <div class="headline font-weight-light">
              Annual Dealership Subscription
            </div>
            <div class="text-uppercase font-weight-light">
              Fee &mdash; RM 225
            </div>
          </v-layout>
        </v-parallax>
        <v-card-text
          v-if="annualSubscription.length > 0"
          class="py-0"
        >
          <v-timeline
            align-top
            dense
          >
            <v-timeline-item
              v-for="(item, index) in annualSubscription"
              :key="index"
              color="amber"
              small
            >
              <v-layout pt-3>
                <v-flex xs4>
                  <strong>{{ item.title }}</strong>
                </v-flex>
                <v-flex>
                  <strong
                    v-if="item.color"
                    :style="item.color"
                  >
                    {{ item.value }}
                  </strong>
                  <strong v-else>{{ item.value }}</strong>
                  <div
                    v-if="item.caption"
                    class="caption"
                    v-html="item.caption"
                  />
                </v-flex>
              </v-layout>
            </v-timeline-item>
          </v-timeline>
          <v-subheader v-html="footnote" />
        </v-card-text>
        <!--v-card-text v-html="kpiDescription" /-->
        <v-parallax
          dark
          height="70"
          src="/img/background.jpg"
        >
          <v-layout
            align-center
            column
            justify-center
          >
            <div class="headline font-weight-light">
              Performance Measurements
            </div>
          </v-layout>
        </v-parallax>
        <v-list
          class="transparent"
          two-line
        >
          <template v-for="(item, index) in descriptions">
            <v-subheader
              v-if="item.header"
              :key="item.header"
              v-html="item.header"
            />

            <v-divider
              v-else-if="item.divider"
              :key="index"
              :inset="item.inset"
            />

            <div
              v-else-if="item.list"
              :key="item.list"
              v-html="item.list"
              style="padding-left: 16px; color: hsla(0, 0%, 100%, 0.7)"
            />

            <v-list-tile
              v-else
              :key="item.title"
              avatar
            >
              <v-list-tile-avatar>
                <v-btn
                  absolute
                  dark
                  fab
                  :ripple="false"
                  color="black"
                >
                  <v-avatar
                    size="50px"
                    :color="item.kpiColor"
                  >
                    <span class="white--text subheader">{{
                      item.kpiAchievement
                    }}</span>
                  </v-avatar>
                </v-btn>
              </v-list-tile-avatar>

              <v-list-tile-content>
                <v-list-tile-title v-html="item.title" />
                <v-list-tile-sub-title v-html="item.subtitle" />
              </v-list-tile-content>
            </v-list-tile>
          </template>
        </v-list>
      </template>
      <template v-else>
        <v-card-title>Performance Measurements</v-card-title>
        <v-card-text>
          <p>
            <span class="font-weight-bold accent">KPI 1</span>: Must hit monthly
            minimum 2 ONEXOX Prepaid activations with minimum recharge of RM30
            (accumulative within the same calendar month) for each MSISDN; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 2</span>: Must hit monthly
            minimum 2 ONEXOX BLACK activations with minimum B39 plan; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 3</span>: Must hit monthly
            minimum 2 ONEXOX BLACK B10 activations wih minumum recharge RM20
            (accumulative within the same calendar month) for each MSISDN; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 4</span>: Must hit monthly
            1 ONEXOX Prepaid activation with minimum recharge of RM30
            (accumulative within the same calendar month) and 1 ONEXOX BLACK B39
            activation &amp; above; or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 5</span>: Must hit monthly
            1 ONEXOX Prepaid activation with minimum recharge of RM30
            (accumulative within the same calendar month) and 1 ONEXOX BLACK B10
            activation with minimum recharge of RM20 (accumulative within the
            same calendar month); or
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 6</span>: Must hit monthly
            1 ONEXOX BLACK activation with minimum B39 plan and 1 ONEXOX BLACK
            B10 activation with minimum recharge of RM20 (accumulative within
            the same calendar month)
          </p>
          <p>
            <span class="font-weight-bold accent">KPI 7</span>: Must hit monthly
            E-Recharge sales total amounting to RM500 &amp; above to the
            subscriber.
          </p>
        </v-card-text>
      </template>
    </v-card>
    <v-card
      v-if="!kpi2022"
      class="transparent"
      flat
    >
      <v-card-title>Demotion of Dealership</v-card-title>
      <v-card-text>
        <p>
          <span class="font-weight-bold accent">Demotion 1</span>: Demoted to
          subscriber profile in the <strong>month of 7th</strong>
          if failed to hit monthly KPI within the KPI cycle (6 months
          consecutively)
        </p>
        <p>
          <span class="font-weight-bold accent">Demotion 2</span>: Demoted to
          subscriber profile in the <strong>month of 7th</strong> if failed to
          maintain monthly
          <strong>RM500 sales top up to subscribers every month</strong> within
          the KPI cycle (6 months consecutively) (Effective 1st August, 2020)
        </p>
      </v-card-text>
    </v-card>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      search: '',
      achievement: 0,
      activeUntil: 'indefinite date',
      alertText: null,
      alertType: 'info',
      allowedMonths: function (month) {
        const ym = month.split('-')
        const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
          'Asia/Kuala_Lumpur',
        )
        const fromDate = DateTime.local(2020, 1, 1).setZone(
          'Asia/Kuala_Lumpur',
        )
        const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

        return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
      },
      announcement: null,
      annualSubscription: [],
      footnote:
        '<div>Please fulfill either <strong>Activation</strong> or <strong>E-recharge Sales</strong> target to be eligible for a waiver.</div>',
      goal: 0,
      kpiAchievement: null,
      kpiDescription: '',
      hideActions: true,
      items: [],
      maxDate: '',
      menu: false,
      month: null,
      monthOf: null,
      title: 'Key Performance Indicator',
    }
  },
  computed: {
    descriptions: function () {
      let description = [
        {
          header:
            'Semua pengedar sedia ada (tidak termasuk Basic Dealer) adalah tertakluk kepada 3 pilihan KPI bulanan yang dinyatakan di bawah.',
        },
        {
          kpiColor: '#B8860B',
          kpiAchievement: 'Bronze',
          title:
            '20 - 39 pengaktifan <span class="grey--text text--lighten-1"></span>',
          subtitle: '&mdash; Layak insentif 12%',
        },
        { divider: true, inset: true },
        {
          kpiColor: '#C0C0C0',
          kpiAchievement: 'Silver',
          title:
            '40 - 49 pengaktifan <span class="grey--text text--lighten-1"></span>',
          subtitle: '&mdash; Layak insentif 13%',
        },
        { divider: true, inset: true },
        {
          kpiColor: '#FFD700',
          kpiAchievement: 'Gold',
          title:
            '50 pengaktifan <span class="grey--text text--lighten-1">(hibah tunai)</span>',
          subtitle: '&mdash; Layak insentif 14%',
        },
      ]
      if (this.kpiSept2022) {
        description = [
          {
            header:
              'Insentif Royalti ini hanya tertakluk kepada rangkaian SLD sahaja',
          },
          {
            kpiColor: '#B8860B',
            kpiAchievement: 'Bronze',
            title: '20 hingga 39 pengaktifan',
            subtitle: '&mdash; Kelayakan insentif 12% (tunai)',
          },
          { divider: true, inset: true },
          {
            kpiColor: '#C0C0C0',
            kpiAchievement: 'Silver',
            title: '40 hingga 49 pengaktifan',
            subtitle: '&mdash; Kelayakan insentif 13% (tunai)',
          },
          { divider: true, inset: true },
          {
            kpiColor: '#FFD700',
            kpiAchievement: 'Gold',
            title: '50 pengaktifan dan ke atas',
            subtitle: '&mdash; Kelayakan insentif 14% (tunai)',
          },
          {
            list: '<br><strong>DEFINASI PENGAKTIFAN</strong><br>&mdash; 1 Prepaid + top up RM35 = 1 KPI<br>&mdash; 1 Black B39FC = 1 KPI<br>&mdash; 1 IC for 1 MSISDN activation per month',
          },
          {
            header: 'Single Level Dealer tidak layak melantik pengedar baharu.',
          },
        ]
      }

      return description
    },
    kpi2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2022, 3, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    kpiSept2022: function () {
      if (!this.monthOf) return false

      const ym = this.monthOf.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone(
        'Asia/Kuala_Lumpur',
      )
      const fromDate = DateTime.local(2022, 9, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    headers: function () {
      if (this.kpiSept2022) {
        return [
          {
            text: 'Indicator',
            align: 'left',
            sortable: false,
            value: 'indicator',
          },
          {
            text: 'Bronze Goal',
            align: 'center',
            sortable: false,
            value: 'bronzegoal',
          },
          {
            text: 'Silver Goal',
            align: 'center',
            sortable: false,
            value: 'silvergoal',
          },
          {
            text: 'Gold Goal',
            align: 'center',
            sortable: false,
            value: 'goldgoal',
          },
          {
            text: 'Achievement',
            align: 'center',
            sortable: false,
            value: 'achievement',
          },
        ]
      } else {
        return [
          {
            text: 'Indicator',
            align: 'left',
            sortable: false,
            value: 'indicator',
          },
          {
            text: 'Goal',
            align: 'center',
            sortable: false,
            value: 'goal',
          },
          {
            text: 'Achievement',
            align: 'center',
            sortable: false,
            value: 'achievement',
          },
        ]
      }
    },
    kpiColor: function () {
      if (
        this.kpiAchievement === 'KPI 01' ||
        this.kpiAchievement === 'KPI 01A'
      ) {
        return 'grey'
      } else if (this.kpiAchievement === 'KPI 02') return 'orange darken-3'
      else if (this.kpiAchievement === 'KPI 03') return '#800000'
      else if (this.kpiAchievement === 'KPI 01B') return 'grey darken-2'
      else if (this.kpiAchievement === 'KPI 01C') return 'grey darken-3'
      else return 'transparent'
    },
  },
  watch: {
    monthOf: function (val) {
      this.items = []
      this.getKpiSLD(val)
      // this.getKpiDescriptionSLD()
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.month = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    customFilter (items, search) {
      return items.filter(
        (activation) =>
          JSON.stringify(activation)
            .toLowerCase()
            .indexOf(search.toLowerCase()) !== -1,
      )
    },
    getKpiDescriptionSLD: function () {
      this.$rest
        .get('getKpiDescriptionSLD.php', createGetParams({}))
        .then(
          function (response) {
            this.kpiDescription = response.data.kpiDescription
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getKpiSLD: function (month) {
      const params = createGetParams({
        month: month,
      })
      this.$rest
        .get('getKpiSLD.php', params)
        .then(
          function (response) {
            let count = 0
            this.items = response.data.kpi
            this.goal = response.data.goal
            this.kpiAchievement = response.data.kpiAchievement
            this.achievement = response.data.achievement
            this.idleMonth = response.data.idleMonth
            this.announcement = response.data.announcement
              ? response.data.announcement
              : null
            if (response.data.annualSubscription) {
              this.annualSubscription = response.data.annualSubscription
            }
            if (response.data.activeUntil) {
              this.activeUntil = response.data.activeUntil
            }
            this.items.forEach((item) => {
              count += item.items.length
            })
            this.hideActions = count < 25
            if (this.achievement >= this.goal) {
              this.alertText =
                'Congratulations! You have achieved your KPI this month.'
              this.alertType = 'success'
            } else if (this.idleMonth >= 6) {
              this.alertText =
                'You have been idle for ' +
                this.idleMonth +
                ' months. Your dealer account will be terminated if you stay idle longer!'
              this.alertType = 'error'
            }
          }.bind(this),
        )
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
